$red: #ff0000;
$primary_horizon: #f58410;
$primary_gunpowder: #5e696d;
$primary_piecesofeight: #93a4aa;
$secoundary_lagoon: #02baf2;
$first_bool: #cc0000;
$black_flag: #364347;
$white: #fff;
$black: #000;
$dark_backdrop: rgba($black, 0.3);
$ligth_backdrop: rgba($black, 0.08);
$text_primary: #364347;
$background_fa: #fafafa;

:export {
    red: $red;
    primary_gunpowder: $primary_gunpowder;
    secoundary_lagoon: $secoundary_lagoon;
    primary_piecesofeight: $primary_piecesofeight;
    first_bool: $first_bool;
    black_flag: $black_flag;
    white: $white;
    black: $black;
    dark_backdrop: $dark_backdrop;
    ligth_backdrop: $ligth_backdrop;
    text_primary: $text_primary;
    background_fa: $background_fa;
    primary_horizon: $primary_horizon;
}
