@import "../../theme/variables.module.scss";

.overlay {
    background-color: $dark_backdrop;
    z-index: 1301;
    color: $secoundary_lagoon;
    position: absolute;
    flex-direction: column;
    &_light {
        background-color: $ligth_backdrop;
    }
    .circular {
        border-radius: 50%;
        box-shadow: inset 0 0 1px 5px $background_fa;
    }
}
