@import "variable.module.scss";

body,
html {
  color: $primary_color;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  line-height: 1.42857143;
}

a:hover,
input[type="date"] {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  inset: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  z-index: 1;
}
