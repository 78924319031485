.drawer {
    width: 256px;
    padding-top: 63px;
    white-space: nowrap;
}

.drawerOpen {
    width: 256px;
    padding-top: 63px;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawerClose {
    width: 72px;
    padding-top: 63px;
    overflow-x: hidden;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
