@import "../../theme/variables.module";
.root {
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    position: absolute;
}

.primary {
    background: $black_flag url("../../assets/images/roadway.jpeg");
}
.secondary {
    background: $background_fa;
}

.center_page {
    align-items: center;
    justify-content: center;
}
