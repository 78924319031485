.login_box {
    width: 256px;
    padding: 24px;
}

.logo {
    width: 100%;
    height: 37px;
    margin: 8px 0 35px 0;
    background-size: auto;
}
