/* Basic colors */
$black: #000000;
$white: #ffffff;

/* Theme colors */
$primary_color: #364347;
$border_color: #b2c6cd;
$secondary_color: #02baf2;
$primary_border_color: #d6e1e5;
$pacific_blue: #0b95bf;
$success_color: #3eb5ac;
$danger_color: #f84545;
$danger_hover_color: #cc0000;
$text_primary: #364347;

$shark: #1d2528;
$nevada: #5e696d;
$hit_gray: #93a4aa;
$hit_gray_a: #9daeb4;
$outer_space: #2f393c;
$concrete: #f3f3f3;
$black_squeeze: #e1eef3;
$swans_down: #d8f0ee;
$keppel_a: #3db5ac;
$lochinvar: #28948c;
$tree_poppy: #f7941e;
$zest: #e08419;
$ecstasy: #f58410;
$gray_a: #999999;
$gray_b: #eeeeee;
$gray_c: #dddddd;
$gray_d: #f1f1f1;
$gray_e: #d4dfe3;
$gray_f: #888888;
$gray_g: #e6e6e6;
$gray_h: #797979;
$gray_i: #e9ecef;
$light_peach: #fde6cf;
$pumpkin: #e96300;
$spring_sky: rgba(225, 238, 243, 0.5);
$button: #0d6efd;
$button_hovered: #0a58ca;

$pale_melon: #fdf2d2;

:export {
  black: $black;
  white: $white;
  primary: $primary_color;
  border: $border_color;
  secondary: $secondary_color;
  primary_border: $primary_border_color;
  pacific_blue: $pacific_blue;
  success_color: $success_color;
  danger_color: $danger_color;
  danger_hover: $danger_hover_color;
  shark: $shark;
  nevada: $nevada;
  hit_gray: $hit_gray;
  hit_gray_a: $hit_gray_a;
  outer_space: $outer_space;
  concrete: $concrete;
  black_squeeze: $black_squeeze;
  swans_down: $swans_down;
  keppel_a: $keppel_a;
  lochinvar: $lochinvar;
  tree_poppy: $tree_poppy;
  zest: $zest;
  ecstasy: $ecstasy;
  gray_a: $gray_a;
  gray_b: $gray_b;
  gray_c: $gray_c;
  gray_d: $gray_d;
  gray_e: $gray_e;
  gray_f: $gray_f;
  gray_g: $gray_g;
  gray_h: $gray_h;
  gray_i: $gray_i;
  light_peach: $light_peach;
  pumpkin: $pumpkin;
  spring_sky: $spring_sky;
  pale_melon: $pale_melon;
  text_primary: $text_primary;
  button: $button;
  button_hovered: $button_hovered;
}
